import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import LoopIcon from '../../assets/loop-icon.svg'
import ShuffleIcon from '../../assets/shuffle-icon.svg'
import PlayIcon from '../../assets/playlist-play-icon.svg'
import PauseIcon from '../../assets/playlist-pause-icon.svg'
import LockIcon from '../../assets/lock-icon.svg'
import {
  AUDIO_WATCH_URL,
  CONTENT_FILTERING,
  CONTENT_WATCH_URL,
  DETAIL_CONTENT,
} from '../../utils/apiUrls'
import useAxios from 'axios-hooks'
import {
  create_share_url,
  getImage,
  getRandomWithOneExclusion,
  getReleaseDate,
} from '../../utils/helper'
import { AppContext } from '../../context/app'
import { intersectionWith, isEmpty, isEqual } from 'lodash'
import CustomImage from '../../components/customImage/customImage'
import ReviewsListing from '../../components/reviewRating'
import CommentListing from '../../components/comments'
import PlaylistBottom from './playlistBottom'
import PlaylistAbout from './playlistAboutus'
import PlaylistRelated from './relatedPlaylist'
import { SubscriptionContext } from '../../context/subscription'
import { useTranslation } from 'react-i18next'
import CustomPlayer from '../player/customPlayer'
import AudioPlaylistPlayer from '../../components/audioPlayer/audioPlaylistPlayer'
import ShareIcon from '../../assets/imgComponents/shareIcon'
import ShareModal from '../../components/shareModal/shareModal'

let isShuffleGlobal = false
let currentIndexGlobal = 0
const PlaylistHome = (theme) => {
  const playlistId = useParams().playlistId
  const { t } = useTranslation()
  const { isLogin, setLoader, setOpenLoginModal, setModalType, accountObj } = useContext(AppContext)
  const { activeSubscription, setOpenSubscriptionModal, setSubscriptions, setRedirectionUrl } =
    useContext(SubscriptionContext)
  const [isShuffle, setIsShuffle] = useState(false)
  const [detailObj, setDetailObj] = useState({})
  const [playListContents, setPlayListContents] = useState([])
  const [currentPlayingContent, setCurrentPlayingContent] = useState({})
  const [videoSource, setVideoSource] = useState(null)
  const [isPlaylistSubscribed, setIsPlaylistSubscribed] = useState(true)
  const [processingLoader, setProcessingLoader] = useState(false)
  const [shareModal, setShareModal] = useState(false)
  const navigate = useNavigate()

  const [{ data: contentWatchUrl, loading: contentWatchUrlLoading }, getContentWatchUrl] = useAxios(
    {
      url: `${CONTENT_WATCH_URL}`,
      method: 'get',
    },
    { manual: true },
  )

  const [{ data: playlistDetail, loading: playlistLoading }, playlistDetailCall] = useAxios(
    {
      url: `${DETAIL_CONTENT}${playlistId}`,
      method: 'get',
    },
    { manual: true },
  )

  const [{ loading: playlistContentLoading }, playlistContentCall] = useAxios(
    {
      url: CONTENT_FILTERING,
      method: 'post',
    },
    { manual: true },
  )

  useEffect(() => {
    playlistDetailCall()
    setProcessingLoader(true)
  }, [playlistId])

  useEffect(() => {
    setLoader(
      contentWatchUrlLoading || playlistLoading || playlistContentLoading || processingLoader,
    )
  }, [contentWatchUrlLoading, playlistLoading, playlistContentLoading, processingLoader])

  useEffect(() => {
    if (contentWatchUrl && contentWatchUrl.success) {
      setVideoSource(contentWatchUrl?.data?.signed_url)
    }
  }, [contentWatchUrl])

  useEffect(async () => {
    if (playlistDetail && playlistDetail.success) {
      const contentObj = playlistDetail.data

      setDetailObj(contentObj)
      let payloadObj =
        contentObj?.playlist_data?.playlist_type === 'MANUAL'
          ? {
              filters: {
                content_ids: contentObj?.playlist_data?.content_data,
                video_types: contentObj.asset_type === 'VIDEO' ? ['SPV', 'MPVC'] : [],
                audio_types: contentObj.asset_type === 'AUDIO' ? ['SPA', 'MPAC'] : [],
              },
            }
          : {
              filters: {
                system_defined: contentObj?.playlist_data?.auto_payload.filter(
                  (info) => info.type === 'system_defined',
                ),
                custom_defined: contentObj?.playlist_data?.auto_payload.filter(
                  (info) => info.type === 'custom_defined',
                ),
                video_types: contentObj.asset_type === 'VIDEO' ? ['SPV', 'MPVC'] : [],
                audio_types: contentObj.asset_type === 'AUDIO' ? ['SPA', 'MPAC'] : [],
              },
              order_by: 'modified_date',
              reverse: false,
            }
      let playlistContent = await playlistContentCall({
        data: {
          ...payloadObj,
          page: 1,
          page_size: 100,
        },
      })

      if (playlistContent.data && playlistContent.data?.success) {
        let sortedData =
          contentObj?.playlist_data?.playlist_type === 'MANUAL'
            ? playlistContent.data?.data.sort((a, b) => {
                let sortingArr = contentObj?.playlist_data?.content_data
                return sortingArr.indexOf(a.content_id) - sortingArr.indexOf(b.content_id)
              })
            : playlistContent.data?.data

        setPlayListContents(sortedData)
        if (!isEmpty(sortedData)) {
          setCurrentPlayingContent(sortedData[0])
        }
      }
      setProcessingLoader(false)
    }
    if (playlistDetail && !playlistDetail.success) {
      setProcessingLoader(false)
    }
  }, [playlistDetail])

  useEffect(async () => {
    if (!isEmpty(currentPlayingContent)) {
      if (await checkRestriction()) {
        getContentWatchUrl({
          url:
            detailObj?.asset_type === 'AUDIO'
              ? `${AUDIO_WATCH_URL}${currentPlayingContent.content_id}`
              : `${CONTENT_WATCH_URL}${currentPlayingContent.content_id}`,
        })
      }
    }
  }, [currentPlayingContent, isLogin])

  const checkPlanExist = (contentActiveSubscription, userSubscription) => {
    return !isEmpty(intersectionWith(contentActiveSubscription, userSubscription, isEqual))
  }

  const emptySubCheck = async (contentSub) => {
    const activeSub = activeSubscription
      .filter((item) => item.status)
      .map((item) => item.subscription)
    return !isEmpty(intersectionWith(contentSub, activeSub, isEqual))
  }

  const checkRestriction = async (openModal = false) => {
    if (!detailObj.is_restricted) {
      return true
    } else {
      if (!isLogin) {
        if (openModal) {
          setModalType('login')
          setOpenLoginModal(true)
        }
        setIsPlaylistSubscribed(false)
        return false
      } else {
        let contentSub = [
          ...detailObj.active_subscription.map((item) => item.id),
          ...detailObj.active_ppv_subscription.map((item) => item.id),
        ]

        const planExist = isEmpty(activeSubscription)
          ? await emptySubCheck(contentSub)
          : checkPlanExist(contentSub, activeSubscription)

        if (!planExist) {
          setIsPlaylistSubscribed(false)
          if (openModal) {
            setRedirectionUrl(window.location.pathname)
            setSubscriptions(contentSub)
            setOpenSubscriptionModal(true)
          }
        } else {
          return true
        }
        return false
      }
    }
  }

  const handleChangeVideo = (index) => {
    currentIndexGlobal = index
    setCurrentPlayingContent(playListContents[index])
  }
  const handlePlayingSequence = () => {
    let currentVideoIndex = currentIndexGlobal
    if (currentVideoIndex < 0) return
    if (isShuffleGlobal) {
      let randomNum = getRandomWithOneExclusion(playListContents.length, currentVideoIndex)
      handleChangeVideo(randomNum)
      return
    }
    handleChangeVideo(currentVideoIndex < playListContents.length - 1 ? currentVideoIndex + 1 : 0)
  }

  const handleCategoryClick = (id, name) => {
    navigate(`/metadata/${id}`)
  }

  const menuComponents = [
    {
      title: t('about'),
      child: (
        <PlaylistAbout
          description={detailObj?.description}
          releaseDate={detailObj?.release_time}
          artists={detailObj?.artists}
          custom_metadata={detailObj?.custom_metadata}
          category={detailObj?.category}
          sub_category={detailObj?.sub_category}
          venues={detailObj?.venues}
        />
      ),
      id: 'about',
    },
    {
      title: t('related_playlist'),
      child: <PlaylistRelated playlistId={playlistId} />,
      id: 'related_playlist',
    },
    {
      title: t('reviews'),
      child: (
        <ReviewsListing
          type={'content'}
          id={playlistId}
          title={detailObj?.title}
          isRefreshed={playlistLoading}
        />
      ),
      id: 'reviews',
    },
    {
      title: t('comments'),
      child: <CommentListing type={'content'} id={playlistId} theme={theme} />,
      id: 'comments',
    },
  ]

  return (
    <div>
      <div className="w-full lg:h-[91.25vh] relative">
        <CustomImage
          imageUrl={getImage(accountObj?.cf_domain_name, detailObj?.poster)}
          imgClass="w-full h-full absolute z-0 lg:block hidden object-cover"
          imgAlt="poster"
        />
        <div className="w-full lg:h-[91.25vh] flex justify-center items-center z-10 lg:bg-transparent bg-[#1b242f]">
          <div
            className="flex flex-col z-10 2xl:w-[1404px] lg:w-[80%] w-full  lg:bg-transparent bg-[#1b242f]"
            style={{
              aspectRatio: '140/64',
              outline: '2px solid transparent',
            }}
          >
            <div
              style={{
                paddingTop: '12px',
                backgroundColor: '#00000040',
                aspectRatio: '1400/104',
                color: '#fff',
              }}
              className="w-full z-10 lg:flex hidden justify-between lg:rounded-xl lg:pb-6 lg:px-7 px-4"
            >
              <div>
                <p style={{ marginBlockEnd: '0' }} className="font-bold xl:text-3xl text-2xl">
                  {detailObj?.title}
                </p>
                <div className="flex">
                  {!isEmpty(detailObj) &&
                    [...detailObj?.category]
                      .filter((ele, idx) => idx < 9)
                      .map((item, index) => (
                        <p
                          style={{ marginBlockEnd: '0' }}
                          className="text-sm lg:mt-3 mt-1 cursor-pointer"
                          key={index}
                          onClick={() => handleCategoryClick(item.id, item.name)}
                        >
                          {index !== 0 && <span>{' , '}</span>}
                          {item.name}
                          {detailObj?.category.length - 1 > 8 && index === 8 ? (
                            <span>...</span>
                          ) : (
                            ''
                          )}
                        </p>
                      ))}
                  {/* {detailObj?.category.map((info) => info.name).toString()} */}
                </div>
              </div>
              <div className="lg:block hidden mt-3">
                <p style={{ marginBlockEnd: '0' }} className="text-sm">
                  {t('published_on') + ':'} {getReleaseDate(detailObj?.publish_time)}
                </p>
                <div className="flex justify-end mt-3">
                  <div className="h-8 w-8 cursor-pointer" onClick={() => setShareModal(true)}>
                    <ShareIcon color={'#fff'} imgClass="w-full h-full" />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                aspectRatio: '1400/524',
                outline: '2px solid transparent',
                color: '#fff',
              }}
              className="w-full z-10 flex lg:flex-row flex-col justify-between lg:mt-3 lg:gap-x-3"
            >
              <div
                style={{
                  backgroundColor: '#000000',
                  outline: '2px solid transparent',
                  aspectRatio: '39/22',
                  overflow: 'hidden',
                }}
                className="z-10 flex justify-center items-center lg:w-2/3  lg:rounded-xl relative"
              >
                {videoSource ? (
                  detailObj?.asset_type === 'AUDIO' ? (
                    <AudioPlaylistPlayer
                      audioSrc={videoSource}
                      detailObj={currentPlayingContent}
                      onAudioEnd={handlePlayingSequence}
                    />
                  ) : (
                    <CustomPlayer
                      contentInfo={currentPlayingContent}
                      contentWatchUrl={videoSource}
                      resumeTime={'0'}
                      handlePlayingSequence={handlePlayingSequence}
                    />
                  )
                ) : null}
                {!videoSource && !isPlaylistSubscribed && (
                  <div className=" w-[70%] md:w-fit">
                    <div className="flex px-5 md:px-0">
                      <img
                        src={LockIcon}
                        style={{
                          width: '18.5px',
                          height: '24.6px',
                        }}
                        alt="LockIcon"
                      />
                      <p
                        style={{ color: '#cecece' }}
                        className="md:ml-2 text-xs md:text-base xl:text-lg text-center "
                      >
                        {t('not_include_in_membership')}
                      </p>
                    </div>
                    <button
                      style={{ background: '#09e9e9', color: '#000' }}
                      className="w-full text-xs md:text-base xl:text-lg rounded-md xl:rounded-lg mt-4 xl:mt-6 py-1"
                      onClick={() => checkRestriction(true)}
                    >
                      {t('upgrade_membership_to_watch')}
                    </button>
                  </div>
                )}
              </div>
              <div
                style={{
                  paddingTop: '12px',
                  aspectRatio: '1400/104',
                  color: '#fff',
                  backgroundColor: '#00000040',
                }}
                className="w-full z-10 lg:hidden flex justify-between lg:rounded-xl lg:pb-6 lg:px-7 px-4"
              >
                <div>
                  <p style={{ marginBlockEnd: '0' }} className="font-bold xl:text-3xl text-2xl">
                    {detailObj?.title}
                  </p>
                  <p style={{ marginBlockEnd: '0' }} className="text-sm lg:mt-3 mt-1">
                    {!isEmpty(detailObj) &&
                      detailObj?.category
                        .filter((ele, idx) => idx < 5)
                        .map((info) => info.name)
                        .toString()}
                  </p>
                </div>
                <div className="lg:block hidden">
                  <p style={{ marginBlockEnd: '0' }} className="text-sm mt-3 ">
                    {t('published_on') + ':'} {getReleaseDate(detailObj?.publish_time)}
                  </p>
                </div>
              </div>
              <div
                style={{
                  outline: '2px solid transparent',
                }}
                className="flex flex-col overflow-hidden lg:w-1/3 lg:h-[35vw] 2xl:h-auto lg:rounded-xl"
              >
                <div
                  style={{
                    height: '49px',
                    paddingBottom: '9px',
                    backgroundColor: '#00000040',
                  }}
                  className="flex justify-between lg:pt-3 pt-1 lg:border-0 border-b lg:px-7 px-4"
                >
                  <p className="font-semibold text-xl">
                    {!playlistContentLoading && !processingLoader
                      ? `${playListContents.length} `
                      : ''}
                    {detailObj.asset_type === 'AUDIO' ? t('audios') : t('videos')}
                  </p>
                  <div style={{ width: '21%' }} className="flex justify-between items-center">
                    <img
                      style={{
                        width: '24px',
                        height: '24px',
                        boxSizing: 'content-box',
                        background: !isShuffle ? 'black' : 'none',
                      }}
                      src={LoopIcon}
                      alt=""
                      className="p-1 rounded-md cursor-pointer"
                      onClick={() => {
                        isShuffleGlobal = false
                        setIsShuffle(false)
                      }}
                    />
                    <img
                      style={{
                        width: '24px',
                        height: '24px',
                        background: isShuffle ? 'black' : 'none',
                        boxSizing: 'content-box',
                      }}
                      src={ShuffleIcon}
                      alt=""
                      className="p-1 rounded-md cursor-pointer"
                      onClick={() => {
                        isShuffleGlobal = true
                        setIsShuffle(true)
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: '#00000040',
                    padding: '3% 6%',
                    maxHeight: `471px`,
                    overflowX: 'hidden',
                  }}
                  className="lg:mt-1 h-full scrollbar-class"
                >
                  {!isEmpty(playListContents) &&
                    playListContents.map((info, index) => (
                      <div
                        key={index}
                        className={`${index && 'mt-6'} flex w-full`}
                        style={{
                          outline: '2px solid transparent',
                        }}
                      >
                        <div
                          style={{ minWidth: '150px', height: '85px' }}
                          className="relative flex justify-center items-center cursor-pointer"
                          onClick={() => handleChangeVideo(index)}
                        >
                          <CustomImage
                            imageUrl={getImage(accountObj?.cf_domain_name, info?.thumbnail)}
                            imgClass="w-full h-full z-0 absolute rounded-lg"
                            loaderClass={'w-full h-full z-0 absolute rounded-lg'}
                            imgAlt={'play'}
                          />
                          {videoSource && currentPlayingContent?.content_id === info?.content_id ? (
                            <div
                              style={{ width: '20px', height: '20px', background: '#ffffff95' }}
                              className="absolute z-10 flex justify-center items-center rounded-full"
                            >
                              <img src={PauseIcon} alt="PauseIcon" />
                            </div>
                          ) : (
                            <img
                              style={{ width: '20px', height: '20px' }}
                              className="absolute z-10"
                              src={PlayIcon}
                              alt="PlayIcon"
                            />
                          )}
                        </div>
                        <div style={{ marginLeft: '5%' }}>
                          <p className="font-semibold 2xl:text-xl xl:text-lg lg:text-base line-clamp-1">
                            {info.title}
                          </p>
                          <p className="xl:text-sm text-xs xl:mt-3 mt-1">
                            {info?.category
                              .filter((ele, idx) => idx < 4)
                              .map((info) => info.name)
                              .toString()}
                          </p>
                          <p className="xl:text-sm text-xs">{info.duration}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PlaylistBottom options={menuComponents} />
      {shareModal && (
        <ShareModal
          onClose={() => setShareModal(false)}
          link={create_share_url()}
          detailObj={detailObj}
        />
      )}
    </div>
  )
}

export default PlaylistHome
