import React, { useEffect, useState } from 'react'
import './filterdropdown.css'
import { isEmpty } from 'lodash'
import CheckedIcon from '../../assets/checkbox-checked.svg'
import UncheckedIcon from '../../assets/checkbox-unchecked.svg'
import RemoveIcon from '../../assets/radio-remove.svg'
import { useTranslation } from 'react-i18next'

const btnStyles = {
  width: '88px',
  height: '30px',
  borderRadius: '4px',
  fontSize: '12px',
}

const FiltersDropDown = ({
  filterDropdown,
  setFilterDropdown,
  filters = [],
  onClearAll,
  onApply,
  setIsFilterApplied,
}) => {
  const { t } = useTranslation()
  const [filtersLocal, setFiltersLocal] = useState([])

  const [drops, setDrops] = useState(filters.map((dat) => ({ opened: false })))

  const handleDropDown = (selectedDropDownIndex) => {
    let tempDrops = drops
    if (tempDrops[selectedDropDownIndex].opened) tempDrops[selectedDropDownIndex].opened = false
    else tempDrops[selectedDropDownIndex].opened = true
    setDrops([...tempDrops])
  }

  useEffect(() => {
    setFiltersLocal(filters)
    setDrops(filters.map((dat) => ({ opened: false })))
    checkFilterApplied()
  }, [filters])

  const checkFilterApplied = () => {
    for (let index = 0; index < filters.length; index++) {
      const filterObj = filters[index]
      const isSelected = filterObj.checkedState.length > 0

      if (isSelected) {
        setIsFilterApplied(true)
        break
      }
      if (!isSelected && index === filters.length - 1) {
        setIsFilterApplied(false)
      }
    }
  }

  return (
    <div
      style={{
        background: '#0f172195',
        color: '#00ffff',
        // height: '300px',
        // top: '62px',
      }}
      className={`filter-dropdown ${
        filterDropdown && 'active'
      } top-[50px] xl:top-[62px] h-auto xl:h-[300px]`}
    >
      {/* filter options */}
      <div
        className="xl:px-14 xl:mt-5 hidden xl:block"
        style={{
          height: '78%',
        }}
      >
        <div
          className="flex justify-start custom-scrollbar-horizontal pb-4"
          style={{
            outline: '2px solid transparent',
            gap: '0 2vw',
            height: '100%',
            overflowX: 'auto',
            overflowY: 'visible',
          }}
        >
          {!isEmpty(filtersLocal) &&
            filtersLocal.map((filterObj) => {
              return filterObj.type === 'radio' ? (
                <div
                  className="flex flex-col"
                  style={{ width: '12%', flexShrink: '0' }}
                  key={filterObj.title}
                >
                  <p
                    style={{
                      borderBottom: '1px solid #00ffff',
                      fontWeight: '600',
                      paddingBottom: '5px',
                      marginBlockEnd: '.75rem',
                    }}
                  >
                    {filterObj.title}
                  </p>
                  <div
                    className="flex flex-col custom-scrollbar-vertical"
                    style={{ gap: '8px 0', height: '100%', overflowY: 'auto' }}
                  >
                    {filterObj.options.map((info, idx) => {
                      return (
                        <span key={idx} className="flex">
                          <input
                            id={info.value}
                            type="radio"
                            className="filter-radio"
                            value={info.value}
                            checked={filterObj.checkedState === info.value}
                            onChange={(e) => {
                              filterObj.onSelect(e.target.value)
                            }}
                          />
                          <label htmlFor={info.value} className="cursor-pointer">
                            {info.name}
                          </label>
                          {filterObj.checkedState === info.value && (
                            <img
                              src={RemoveIcon}
                              alt=""
                              className="ml-2 cursor-pointer"
                              onClick={() => filterObj.onSelect('')}
                            />
                          )}
                        </span>
                      )
                    })}
                  </div>
                </div>
              ) : (
                <div
                  className="flex flex-col"
                  style={{ width: '12%', flexShrink: '0' }}
                  key={filterObj.title}
                >
                  <p
                    style={{
                      borderBottom: '1px solid #00ffff',
                      fontWeight: '600',
                      paddingBottom: '5px',
                      marginBlockEnd: '.75rem',
                    }}
                  >
                    {filterObj.title}
                  </p>
                  <div
                    className="flex flex-col custom-scrollbar-vertical"
                    style={{ gap: '8px 0', height: '100%', overflowY: 'auto' }}
                  >
                    {filterObj.options.map((info, idx) => {
                      return (
                        <span key={idx}>
                          <input
                            id={info.value}
                            value={info.value}
                            checked={filterObj.checkedState.includes(info.value)}
                            onChange={(e) => {
                              filterObj.onSelect(e.target.checked, e.target.value)
                            }}
                            type="checkbox"
                            name={info.name}
                            className="filter-asset-manager-checkbox"
                          />
                          <label htmlFor={info.value} className="cursor-pointer">
                            <img src={CheckedIcon} alt="" className="checked-icon mr-2" />
                            <img src={UncheckedIcon} alt="" className="unchecked-icon mr-2" />
                            {info.name}
                          </label>
                        </span>
                      )
                    })}
                  </div>
                </div>
              )
            })}
        </div>
      </div>

      {/* filter options mobile*/}

      <div className="mt-2 block  xl:hidden">
        {!isEmpty(filtersLocal) &&
          filtersLocal.map((filterObj, idx) => {
            return (
              <div
                key={idx}
                className="group xl:relative static h-full py-2"
                style={{ borderBottom: '1px solid currentColor' }}
                tabIndex={100}
              >
                <button
                  className={`xl:w-max w-full flex justify-between items-center`}
                  style={{
                    border: 'none',
                    padding: '0 39px',
                  }}
                  onClick={() => handleDropDown(idx)}
                >
                  <span className={`text-xs font-bold`}>{filterObj?.title}</span>

                  <svg
                    className={`${
                      drops[idx]?.opened ? 'rotate-profile-arrow' : 'reset-profile-arrow'
                    } w-4 h-4 ml-1`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>

                <ul
                  className={`${drops[idx]?.opened ? 'block' : 'hidden'} 
            xl:absolute static top-[100%] xl:w-auto w-full lg:-left-12 -left-6 xl:left-0 mt-2 pt-2`}
                  style={{
                    paddingInlineStart: '0',
                    marginBottom: '0',
                    whiteSpace: 'nowrap',
                    borderTop: '1px solid currentColor',
                  }}
                >
                  <div
                    className="flex flex-col w-full"
                    style={{ flexShrink: '0', padding: '0px 50px', color: 'currentcolor' }}
                    key={filterObj.title}
                  >
                    {filterObj.type === 'radio' ? (
                      <div
                        className="flex flex-col custom-scrollbar-vertical"
                        style={{ gap: '8px 0', height: '100%', overflowY: 'auto' }}
                      >
                        {filterObj.options.map((info, idx) => {
                          return (
                            <span key={idx} className="flex">
                              <input
                                id={info.value}
                                type="radio"
                                className="filter-radio"
                                value={info.value}
                                checked={filterObj.checkedState === info.value}
                                onChange={(e) => {
                                  filterObj.onSelect(e.target.value)
                                }}
                              />
                              <label htmlFor={info.value} className="cursor-pointer">
                                {info.name}
                              </label>
                              {filterObj.checkedState === info.value && (
                                <img
                                  src={RemoveIcon}
                                  alt=""
                                  className="ml-2 cursor-pointer"
                                  onClick={() => filterObj.onSelect('')}
                                />
                              )}
                            </span>
                          )
                        })}
                      </div>
                    ) : (
                      <div
                        className="flex flex-col custom-scrollbar-vertical"
                        style={{ gap: '8px 0', height: '100%', overflowY: 'auto' }}
                      >
                        {filterObj.options.map((info, idx) => {
                          return (
                            <span key={idx}>
                              <input
                                id={info.value}
                                value={info.value}
                                checked={filterObj.checkedState.includes(info.value)}
                                onChange={(e) => {
                                  filterObj.onSelect(e.target.checked, e.target.value)
                                }}
                                type="checkbox"
                                name={info.name}
                                className="filter-asset-manager-checkbox"
                              />
                              <label htmlFor={info.value} className="cursor-pointer">
                                <img src={CheckedIcon} alt="" className="checked-icon mr-2" />
                                <img src={UncheckedIcon} alt="" className="unchecked-icon mr-2" />
                                {info.name}
                              </label>
                            </span>
                          )
                        })}
                      </div>
                    )}
                  </div>
                </ul>
              </div>
            )
          })}
      </div>

      {/* clear and apply buttons */}
      <div
        className="flex justify-center xl:justify-end px-4 pb-4 mt-4 xl:mt-0"
        style={{ gap: '0 30px' }}
      >
        <button
          className=""
          style={{
            color: '#00ffff',
            backgroundColor: 'transparent',
            border: '1px solid #00ffff',
            ...btnStyles,
          }}
          onClick={() => {
            onClearAll()
            setFilterDropdown(false)
          }}
        >
          {t('clear_all')}
        </button>
        <button
          className=""
          style={{
            color: '#000000',
            backgroundColor: '#00ffff',
            ...btnStyles,
          }}
          onClick={() => {
            onApply()
            setFilterDropdown(false)
          }}
        >
          Apply
        </button>
      </div>
    </div>
  )
}

export default FiltersDropDown
