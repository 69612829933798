import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import useTemplateHook from '../templateHook'
import EditMenu from '../editMenu'
import { isEmpty } from 'lodash'
import EmailIconComponent from '../imgComponents/emailIcon'
import PhoneIconComponent from '../imgComponents/phoneIcon'
import { withTheme } from 'theming'
import CustomImage from '../../customImage/customImage'

const AppFocusedFooter = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onSwap,
  onDelete,
  theme,
}) => {
  const { data, id } = componentData
  const [hoverState, setHoverState] = useState('')
  const {
    customNavigate,
    getTemplateImage,
    FBLogo,
    InstaLogo,
    TGLogo,
    YTLogo,
    TwitterLogo,
    BitchuteLogo,
    RumbleLogo,
    BoxcastLogo,
    TikTokLogo,
    getExternalLink,
    AndroidStore,
    AppleStore,
    AndroidTv,
    AppleTv,
    RokuTv,
    FireTv,
    SamsungTv,
    LGTv,
    DMCALogo,
  } = useTemplateHook()

  //   const [searchParams] = useSearchParams()
  const location = useLocation()
  const [isWordpressPages, setIsWordpressPages] = useState(false)

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  useEffect(() => {
    if (location) {
      if (['/be-a-guest', '/be-a-host', '/be-the-change'].includes(location.pathname)) {
        setIsWordpressPages(true)
      } else {
        setIsWordpressPages(false)
      }
    }
  }, [location])

  //   const mobileApp = searchParams.get('mobileApp') === 'true'

  const socialIcons = {
    facebook: FBLogo,
    twitter: TwitterLogo,
    instagram: InstaLogo,
    telegram: TGLogo,
    youtube: YTLogo,
    bitchute: BitchuteLogo,
    tiktok: TikTokLogo,
    rumble: RumbleLogo,
    boxcast: BoxcastLogo,
  }
  const appIcons = {
    android: AndroidStore,
    apple: AppleStore,
  }
  const tvIcons = {
    android: {
      icon: AndroidTv,
      aspectRatio: '107/18',
      height: '18px',
    },
    apple: {
      icon: AppleTv,
      aspectRatio: '41/20',
      height: '20px',
    },
    roku: {
      icon: RokuTv,
      aspectRatio: '68/21',
      height: '21px',
    },
    amazon: {
      icon: FireTv,
      aspectRatio: '73/21',
      height: '21px',
    },
    samsung: {
      icon: SamsungTv,
      aspectRatio: '67/26',
      height: '26px',
    },
    lg: {
      icon: LGTv,
      aspectRatio: '85/17',
      height: '17px',
    },
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <footer
      className={`${isEdit && hoverState && 'component-hover-class'}`}
      style={{
        backgroundColor: getStyleValue('component_background', 'primary_color', '#000000'),
      }}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {!isWordpressPages && (
        <div
          style={{
            backgroundColor: getStyleValue('component_background', 'color', theme.c1),
          }}
          className="h-8 md:h-12 lg:h-20 xl:h-16 2xl:h-32"
        />
      )}

      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onDelete={() => onDelete(id)}
          onEdit={() => handleModal()}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
        />
      )}
      <div className="lg:pl-[3.9%] lg:pr-[5%] 2xl:pr-[9%] pt-8 pb-7 lg:pt-[61px] lg:pb-4 relative ">
        <div className="flex lg:flex-row flex-col items-center lg:items-stretch">
          <div className="w-full lg:w-[20.3%]">
            <div
              className="flex justify-center lg:justify-start"
              onClick={() => customNavigate('DIRECT', '/home', isEdit)}
            >
              <CustomImage
                imageUrl={getTemplateImage(data?.image_s3key)}
                imgClass="2xl:w-[140px] 2xl:h-[61px] xl:w-28 xl:h-12 lg:w-20 lg:h-9 w-[78px] h-[34px] cursor-pointer"
                imgStyle={{ backgroundColor: 'transparent' }}
                loaderClass="2xl:w-[140px] 2xl:h-[61px] xl:w-28 xl:h-12 lg:w-20 lg:h-9 w-[78px] h-[34px] cursor-pointer"
                loaderStyle={{ background: '#5A5A5A50' }}
                imgAlt={'Logo'}
              />
            </div>
            <div className="text-xs lg:text-sm  mt-[31px]">
              {data?.email && (
                <p className="flex mb-3 justify-center lg:justify-start">
                  <a
                    href={`mailto:${data?.email}`}
                    title={`mailto:${data?.email}`}
                    rel="noopener noreferrer"
                    className="flex items-center text-decoration-none"
                    style={{ color: getStyleValue('email', 'color', 'white') }}
                  >
                    <div className="mr-2" style={{ marginTop: '1.5px' }}>
                      <EmailIconComponent
                        color={getStyleValue('email', 'color', 'white')}
                        iconClass="w-3 md:w-4"
                      />
                    </div>
                    <span
                      style={{
                        fontFamily: getStyleValue('email', 'font', 'sans-serif'),
                        textAlign: getStyleValue('email', 'alignment'),
                      }}
                      className={`${getStyleValue('email', 'font_format', '')} mx-1`}
                    >
                      {data?.email}
                    </span>
                  </a>
                </p>
              )}

              {data?.phone && (
                <p className="flex justify-center lg:justify-start mb-0">
                  <a
                    href={`tel:${data?.phone}`}
                    title={`tel:${data?.phone}`}
                    rel="noopener noreferrer"
                    className="flex items-center  text-decoration-none"
                    style={{ color: getStyleValue('phone', 'color', 'white') }}
                  >
                    <div className="mr-2 w-3">
                      <PhoneIconComponent
                        color={getStyleValue('phone', 'color', 'white')}
                        iconClass="w-3 md:w-4"
                      />
                    </div>
                    <span
                      style={{
                        fontFamily: getStyleValue('phone', 'font', 'sans-serif'),
                        textAlign: getStyleValue('phone', 'alignment'),
                      }}
                      className={`${getStyleValue('phone', 'font_format', '')} mx-1`}
                    >
                      {data?.phone}
                    </span>
                  </a>
                </p>
              )}
            </div>
            <div
              className={`flex flex-col items-center lg:items-start text-center  mt-[20px] lg:mt-7 `}
            >
              <p
                style={{
                  color: getStyleValue('social_media_text', 'color', '#f1b814'),
                  fontFamily: getStyleValue('social_media_text', 'font', ''),
                  textAlign: getStyleValue('social_media_text', 'alignment', ''),
                }}
                className={`${getStyleValue(
                  'social_media_text',
                  'font_format',
                  'font-bold',
                )} text-[10px] lg:text-sm  mb-0`}
              >
                {data?.social_handles_text}
              </p>
              <div
                className="mt-[12px] lg:mt-4 lg:grid"
                style={{
                  width: '80%',
                  // gridAutoFlow: 'column',
                  gridTemplateColumns: 'repeat(5, 1fr)',
                  gridTemplateRows: 'repeat(2 ,max-content)',
                  gridColumnGap: '0px',
                  gridRowGap: '6px',
                }}
              >
                {[...data?.social_handles].map((item, index) => (
                  <div
                    className=" md:w-6 w-4 first:ml-0 ml-3 lg:ml-0 mt-1 inline-block"
                    key={index}
                  >
                    <a href={getExternalLink(item.url)} target="_blank" rel="noreferrer">
                      <img className="w-full" src={socialIcons[item.name]} alt={`${item.name}`} />
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <p
              className={`mt-[76px] hidden lg:block text-[10px] lg:text-sm ${getStyleValue(
                'copyright',
                'font_format',
                '',
              )} mb-0`}
              style={{
                fontFamily: getStyleValue('copyright', 'font', ''),
                textAlign: getStyleValue('copyright', 'alignment', ''),
                color: getStyleValue('copyright', 'color', '#dfdada'),
              }}
            >
              {data?.copyright_text}
            </p>
          </div>
          <div className="w-full lg:w-[51.3%] flex lg:flex-row flex-col items-center lg:items-start justify-center lg:justify-between mt-[20px] lg:mt-0">
            <div className={`w-full lg:w-1/3 lg:text-left  ${!isEdit && 'text-center'}`}>
              <p
                style={{
                  color: getStyleValue('social_media_text', 'color', '#f1b814'),
                  fontFamily: getStyleValue('social_media_text', 'font', ''),
                  textAlign: getStyleValue('social_media_text', 'alignment', ''),
                }}
                className={`${getStyleValue(
                  'social_media_text',
                  'font_format',
                  'font-bold',
                )} text-[10px] lg:text-sm  lg:mb-5 mb-[5px]`}
              >
                {data?.column_one_menus?.header_text}
              </p>
              {data?.column_one_menus?.sub_menu_items.map((page, index) => (
                <div
                  style={{
                    textAlign: getStyleValue('sitemap_submenu', 'alignment', ''),
                    color: getStyleValue('sitemap_submenu', 'color', 'white'),
                    fontFamily: getStyleValue('sitemap_submenu', 'font', ''),
                  }}
                  className={''}
                  key={index}
                >
                  <p
                    className={`cursor-pointer text-[10px] lg:text-sm lg:mb-[10px] mb-[5px] lg:w-fit ${getStyleValue(
                      'sitemap_submenu',
                      'font_format',
                      '',
                    )}`}
                    title={page.text}
                    onClick={() => handleRedirect(page.link)}
                  >
                    {page?.text}
                  </p>
                </div>
              ))}
            </div>
            <div className={`w-full lg:w-1/3 lg:text-left  ${!isEdit && 'text-center'}`}>
              <p
                style={{
                  color: getStyleValue('social_media_text', 'color', '#f1b814'),
                  fontFamily: getStyleValue('social_media_text', 'font', ''),
                  textAlign: getStyleValue('social_media_text', 'alignment', ''),
                }}
                className={`${getStyleValue(
                  'social_media_text',
                  'font_format',
                  'font-bold',
                )} text-[10px] lg:text-sm  lg:mb-5 mb-[5px]`}
              >
                {data?.column_two_menus?.header_text}
              </p>
              {data?.column_two_menus?.sub_menu_items.map((page, index) => (
                <div
                  style={{
                    textAlign: getStyleValue('sitemap_submenu', 'alignment', ''),
                    color: getStyleValue('sitemap_submenu', 'color', 'white'),
                    fontFamily: getStyleValue('sitemap_submenu', 'font', ''),
                  }}
                  className={''}
                  key={index}
                >
                  <p
                    className={`cursor-pointer text-[10px] lg:text-sm lg:mb-[10px] mb-[5px] lg:w-fit ${getStyleValue(
                      'sitemap_submenu',
                      'font_format',
                      '',
                    )}`}
                    title={page.text}
                    onClick={() => handleRedirect(page.link)}
                  >
                    {page?.text}
                  </p>
                </div>
              ))}
            </div>
            <div className={`w-full lg:w-1/3 lg:text-left  ${!isEdit && 'text-center'}`}>
              <p
                style={{
                  color: getStyleValue('social_media_text', 'color', '#f1b814'),
                  fontFamily: getStyleValue('social_media_text', 'font', ''),
                  textAlign: getStyleValue('social_media_text', 'alignment', ''),
                }}
                className={`${getStyleValue(
                  'social_media_text',
                  'font_format',
                  'font-bold',
                )} text-[10px] lg:text-sm  lg:mb-5 mb-[5px]`}
              >
                {data?.column_three_menus?.header_text}
              </p>
              {data?.column_three_menus?.sub_menu_items.map((page, index) => (
                <div
                  style={{
                    textAlign: getStyleValue('sitemap_submenu', 'alignment', ''),
                    color: getStyleValue('sitemap_submenu', 'color', 'white'),
                    fontFamily: getStyleValue('sitemap_submenu', 'font', ''),
                  }}
                  className={''}
                  key={index}
                >
                  <p
                    className={`cursor-pointer text-[10px] lg:text-sm lg:mb-[10px] mb-[5px] lg:w-fit ${getStyleValue(
                      'sitemap_submenu',
                      'font_format',
                      '',
                    )}`}
                    title={page.text}
                    onClick={() => handleRedirect(page.link)}
                  >
                    {page?.text}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div
            className=" w-[73.5%] lg:w-[28.4%] flex justify-center lg:justify-end border-t border-l-0 lg:border-l lg:border-t-0 lg:h-[270px] mt-8 lg:mt-0"
            style={isEdit ? { borderLeft: '1px solid white' } : { borderColor: 'white' }}
          >
            <div className="w-full lg:w-3/4 mt-[20px] lg:mt-0">
              <div className={`flex flex-col items-center lg:items-start`}>
                <p
                  style={{
                    color: getStyleValue('mobile_app_text', 'color', '#f1b814'),
                    fontFamily: getStyleValue('mobile_app_text', 'font', ''),
                    textAlign: getStyleValue('mobile_app_text', 'alignment', ''),
                  }}
                  className={`${getStyleValue(
                    'mobile_app_text',
                    'font_format',
                    'font-bold',
                  )} text-[10px] lg:text-sm mb-0`}
                >
                  {data?.app_link_text}
                </p>
                <div className="mt-[12px] md:mt-5 flex justify-between">
                  {[...data?.app_link].map((item, index) => (
                    <div
                      style={{ height: '34px' }}
                      className="ml-8 first:ml-0 inline-block"
                      key={index}
                    >
                      <a href={getExternalLink(item.url)} target="_blank" rel="noreferrer">
                        <img className="h-full" src={appIcons[item.name]} alt={`${item.name}`} />
                      </a>
                    </div>
                  ))}
                  {data?.dmca_logo?.enabled ? (
                    <div
                      className="first:ml-0 ml-8 cursor-pointer"
                      style={{ height: '34px', width: '68px' }}
                      onClick={() => handleRedirect(data?.dmca_logo?.link)}
                    >
                      <img className="w-full h-full " src={DMCALogo} alt={`DMCALogo`} />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={`mt-7  lg:mt-10 flex flex-col items-center lg:items-start `}>
                <p
                  style={{
                    color: getStyleValue('tv_app_text', 'color', '#f1b814'),
                    fontFamily: getStyleValue('tv_app_text', 'font', ''),
                    textAlign: getStyleValue('tv_app_text', 'alignment', ''),
                  }}
                  className={`${getStyleValue(
                    'tv_app_text',
                    'font_format',
                    'font-bold',
                  )} text-[10px] lg:text-sm mb-0`}
                >
                  {data?.tv_link_text}
                </p>
                <div
                  className="mt-[12px] md:mt-5 grid grid-cols-1 lg:grid-cols-2 gap-y-4 
                 lg:gap-x-[77px]"
                >
                  {[...data?.tv_link].map((item, index) => (
                    <div className="justify-center lg:justify-start first:ml-0 flex" key={index}>
                      <a href={getExternalLink(item.url)} target="_blank" rel="noreferrer">
                        <img
                          style={{
                            height: tvIcons[item.name].height,
                            aspectRatio: tvIcons[item.name].aspectRatio,
                          }}
                          className=""
                          src={tvIcons[item.name].icon}
                          alt={`${item.name}`}
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
              <p
                className={`mt-[50px] text-center block lg:hidden text-[10px] ${getStyleValue(
                  'copyright',
                  'font_format',
                  '',
                )}`}
                style={{
                  fontFamily: getStyleValue('copyright', 'font', ''),
                  textAlign: getStyleValue('copyright', 'alignment', ''),
                  color: getStyleValue('copyright', 'color', '#dfdada'),
                }}
              >
                {data?.copyright_text}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default withTheme(AppFocusedFooter)
